import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import './App.css';
import Router from './routes';
import ThemeProvider from './theme';
import { getAuth } from 'firebase/auth'; // Firebase v9+
import { useIdTokenResult } from 'reactfire';

import { FirebaseAppProvider, DatabaseProvider, AuthProvider, useFirebaseApp } from 'reactfire';

const theme = createTheme();
export default function App() {
  const app = useFirebaseApp();
  const auth = getAuth(app);



  return (
    <ThemeProvider theme={theme}>
      <AuthProvider sdk={auth}>
        <Router />
      </AuthProvider>
    </ThemeProvider>
  );
}



