import { Navigate, useRoutes } from 'react-router-dom';
import React from 'react';

const Register = React.lazy(() => import('./admin/Register'));
const RegisterCandidate = React.lazy(() => import('./admin/RegisterCandidate'));
const Landing = React.lazy(() => import('./landing/home'));
const Privacy = React.lazy(() => import('./landing/privacy'));
const CapturePhoto = React.lazy(() => import('./admin/CapturePhoto'));
const Dashboard = React.lazy(() => import('./admin/Dashboard'));
const CandidateDetail = React.lazy(() => import('./admin/CandidateDetail'));
const EndProcess = React.lazy(() => import('./admin/EndProcess'));
const Resume = React.lazy(() => import('./admin/ResumeDetail'));
const DashboardLayout = React.lazy(() => import('./admin/layouts/dashboard'));
const RegisterLayout = React.lazy(() => import('./admin/layouts/dashboard/RegisterLayout'));
const Verify = React.lazy(() => import('./landing/register'));
const Reports = React.lazy(() => import('./admin/Reports'));
const DashboardUsers = React.lazy(() => import('./admin/DashboardUsers'));
const CreateDashboardUser = React.lazy(() => import('./admin/CreateDashboardUser'));

export default function Routes() {
    return useRoutes([
        {
            path: '/', element: <React.Suspense fallback={<>...</>}>
                <Landing />
            </React.Suspense>
        },
        {
            path: '/register', element: <React.Suspense fallback={<>...</>}>
                <Register />
            </React.Suspense>
        },
        {
            path: '/legal/privacy', element: <React.Suspense fallback={<>...</>}>
                <Privacy />
            </React.Suspense>
        },
        {
            path: '/candidate/:id', element: <React.Suspense fallback={<>...</>}>
                <RegisterCandidate />
            </React.Suspense>
        },
        {
            path: '/camera/:id', element: <React.Suspense fallback={<>...</>}>
                <CapturePhoto />
            </React.Suspense>
        },
        {
            path: '/endprocess/:id', element: <React.Suspense fallback={<>...</>}>
                <EndProcess />
            </React.Suspense>
        },
        {
            path: '/admin', element: <React.Suspense fallback={<>...</>}>
                <DashboardLayout />
            </React.Suspense>,
            children: [
                {
                    path: '', element: <React.Suspense fallback={<>...</>}>
                        <Dashboard />
                    </React.Suspense>
                },
                {
                    path: 'users', element: <React.Suspense fallback={<>...</>}>
                        <Dashboard />
                    </React.Suspense>
                },
                {
                    path: 'users/:id', element: <React.Suspense fallback={<>...</>}>
                        <CandidateDetail />
                    </React.Suspense>
                },
                {
                    path: 'users/verify', element: <React.Suspense fallback={<>...</>}>
                        <Verify />
                    </React.Suspense>
                },
                {
                    path: 'reports', element: <React.Suspense fallback={<>...</>}>
                        <Reports />
                    </React.Suspense>
                },
                {
                    path: 'licenses', element: <React.Suspense fallback={<>...</>}>
                        <DashboardUsers />
                    </React.Suspense>
                },
                {
                    path: 'licenses/:id', element: <React.Suspense fallback={<>...</>}>
                        <CreateDashboardUser />
                    </React.Suspense>
                },
                {
                    path: 'register', element: <React.Suspense fallback={<>...</>}>
                        <Register />
                    </React.Suspense>
                },
                {
                    path: 'resume/:id', element: <React.Suspense fallback={<>...</>}>
                        <Resume />
                    </React.Suspense>
                },
                {
                    path: 'endprocess/:id', element: <React.Suspense fallback={<>...</>}>
                        <EndProcess />
                    </React.Suspense>
                },
                {
                    path: 'candidate/:id', element: <React.Suspense fallback={<>...</>}>
                        <RegisterCandidate />
                    </React.Suspense>
                }
            ]
        },
        { path: '*', element: <Navigate to="/" /> },
    ]);
}

